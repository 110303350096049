import React from 'react'
import styled from 'styled-components'

// Hooks
import useIsMobile from '@hooks/useIsMobile'

// Components

import { Heading2, Caption } from '@components/Typography'
import SpacerComponent from '@components/SpacerComponent'

type PersonProps = {
  gridArea: string
  src: string
  name: string
  job: string
}

const Person: React.FC<PersonProps> = props => {
  return (
    <Wrapper gridArea={props.gridArea}>
      <Image src={props.src} />
      <Content>
        <Heading2>{props.name}</Heading2>
        <SpacerComponent bottom='xs' />
        <Job>{props.job}</Job>
      </Content>
    </Wrapper>
  )
}

export default Person

const Job = styled(Caption)`
  color: ${props => props.theme.colors.yellow.default};
`

const Wrapper = styled.div<{ gridArea: string }>`
  grid-area: ${({ gridArea }) => gridArea};
  height: 100%;
  width: 100%;
  position: relative;
  cursor: default;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    max-height: 50vw;
  }
`

const Content = styled.div`
  transition: opacity 0.3s ease-in-out;
  display: flex;
  opacity: 0;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(36, 34, 86, 0.9);
  &:hover {
    opacity: 1;
  }
`

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`
