import React from 'react'
import styled from 'styled-components'

// Components
import SectionComponent from '@components/SectionComponent'
import Spacer from '@components/SpacerComponent'
import { Heading, Heading2, Highlight, Paragraph } from '@components/Typography'
import Grid from '@components/ViewGridComponent'
import Person from './Person'

// Assets
import Chloe from './images/Chloe.jpg'
import Owen from './images/Owen.jpeg'
import Sam from './images/Sam.png'
import Stefan from './images/Stefan.jpg'

// Hooks
import { FadeIn } from '@components/Animations'
import SpacerComponent from '@components/SpacerComponent'
import SquaredLinkComponent from '@components/SquaredLinkComponent'
import useIsMobile from '@hooks/useIsMobile'
import useIsTablet from '@hooks/useIsTablet'
import { } from 'styled-components/macro'
import useSetBackgroundColor from '../../../hooks/useSetBackgroundColor'

type Member = {
  src: any
  name: string
  job: string
}

const Team: React.FC = () => {
  const { ref } = useSetBackgroundColor()

  const isTablet = useIsTablet()
  const isMobile = useIsMobile()

  const teamMembers: Member[] = [
    {
      name: 'Owen',
      job: 'Chief Product Nerd | Co-founder',
      src: Owen,
    },
    {
      name: 'Sam',
      job: 'Admiral of Ops | Co-founder',
      src: Sam,
    },
    {
      name: 'Stef',
      job: 'Freelance Designer',
      src: Stefan,
    },
    {
      name: 'Chloe',
      job: 'Freelance Designer',
      src: Chloe,
    },
    // {
    //   name: 'Kirat',
    //   job: 'Freelance QA Tester',
    //   src: Kirat,
    // },
  ]

  return (
    <SectionComponent id='team'>
      <Grid.Row>
        <Grid.Item column='1 / 13' justifySelfCenter>
          <FadeIn>
            <Heading centered ref={ref}>
              Meet <Highlight>the team</Highlight>
            </Heading>
          </FadeIn>
          <Spacer bottom={isMobile ? 'xl' : 'xl2'} />
        </Grid.Item>
      </Grid.Row>
      <Grid.FullScreen>
        <TeamGrid isMobile={isTablet}>
          {teamMembers.map(member => {
            return (
              <Person
                src={member.src}
                name={member.name}
                job={member.job}
                gridArea={member.name}
              />
            )
          })}
          <TeamContent>
            <FadeIn
              css={`
                display: flex;
                place-items: flex-start;
                justify-content: center;
                flex-direction: column;
              `}
            >
              <Spacer bottom='sm'>
                <Paragraph>
                  <b>Team culture is a big thing for us</b>, we strive to be
                  transparent in all our activities, internally and externally.
                </Paragraph>
              </Spacer>
              <Paragraph>
                Being approachable, honest, passionate, and human-focused lets
                us deliver tech for good digital products that have a genuine
                social impact.{' '}
              </Paragraph>
            </FadeIn>
          </TeamContent>
        </TeamGrid>
      </Grid.FullScreen>
      <SpacerComponent bottom='xl3' />
      <Grid.Row>
        <Grid.Item column={isMobile ? '1 / 13' : '3 / 11'} justifySelfCenter>
          <FadeIn threshold={1}>
            <Spacer bottom='sm'>
              <Heading2 centered={!isMobile} as='p' font='display'>
                It’s not about churning out high volumes of work, but
                collaboratively solving problems and making a difference through
                the clever use of tech.
              </Heading2>
            </Spacer>
            <Paragraph size='lg' centered={!isMobile}>
              <Highlight>
                It’s not just an ideal. It’s the Big Lemon ethos.
              </Highlight>
            </Paragraph>
          </FadeIn>
          <SpacerComponent bottom={isMobile ? 'xl2' : 'xl3'} />
        </Grid.Item>
      </Grid.Row>
      <SpacerComponent bottom='xl3' />
      <Grid.Row>
        <Grid.Item column={isMobile ? '1 / 13' : '3 / 11'} justifySelfCenter>
          <FadeIn>
            <Heading centered>
              Fancy a flavour of our <Highlight>*cough*</Highlight> eclectic
              playlist?
            </Heading>
          </FadeIn>
        </Grid.Item>
      </Grid.Row>
      <SpacerComponent bottom='base' />
      <Grid.Row>
        <Grid.Item justifySelfCenter column='1 / 13'>
          <FadeIn threshold={1}>
            <SquaredLinkComponent
              href='https://open.spotify.com/playlist/5y0E7V0H5RTwxtyidTIRR6?si=1ffd250f67094074'
              label='Check it out on spotify'
              target='_blank'
            />
          </FadeIn>
        </Grid.Item>
      </Grid.Row>
    </SectionComponent>
  )
}

export default Team

const DesktopGrid = `
  'Owen   Sam    Stef     Chloe'
  'copy  copy   copy     copy'
`

const MobileGrid = `
  'Owen   Sam'
  'Stef   Chloe'
  'copy   copy'
  'copy   copy'
`

const TeamGrid = styled.div<{ isMobile: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ isMobile }) =>
    isMobile ? 'repeat(2, minmax(0, 50%))' : 'repeat(4, minmax(0, 1fr))'};
  grid-template-areas: ${({ isMobile }) =>
    isMobile ? MobileGrid : DesktopGrid};
`

const TeamContent = styled.div`
  grid-area: copy;
  background-color: ${({ theme }) => theme.colors.coral.default};
  padding: ${({ theme }) => theme.spacing.xl};
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding: ${({ theme }) => theme.spacing.lg};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: ${({ theme }) => theme.spacing.base};
  }

  & p {
    color: #ffffff;
  }
`
