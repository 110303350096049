import SeoComponent from '@components/SeoComponent'
import React from 'react'
import { ThemeProvider } from 'styled-components'

import { darkPurpleTheme } from '../themes'
// Views
import { Team, Who } from '../views/AboutSections'

// Assets
import PotteryGang from '@assets/images/pottery-gang.jpg'

interface AboutProps {}

const About: React.FC<AboutProps> = () => {
  return (
    <>
      <SeoComponent
        title='Big Lemon | Tech for Good Web Development, Wales'
        description='We’re a small group of people with a bloody big mission: delivering tech for good web development and digital products that create positive change.'
        image={PotteryGang}
        internalImage
      />
      <ThemeProvider theme={darkPurpleTheme}>
        <Who />
      </ThemeProvider>
      <ThemeProvider theme={darkPurpleTheme}>
        <Team />
      </ThemeProvider>
      {/* <ThemeProvider theme={paleBlueTheme}>
        <What />
      </ThemeProvider> */}
      {/* <ThemeProvider theme={blueTheme}>
        <NotAbout />
      </ThemeProvider> */}
    </>
  )
}

export default About
