import React from 'react'
import styled, { useTheme } from 'styled-components'

// Components
import SectionComponent from '@components/SectionComponent'
import HeadingSection from '@components/HeadingSectionComponent'
import Grid from '@components/ViewGridComponent'
import {
  Highlight,
  Heading,
  Paragraph,
  Heading3,
  Heading2,
} from '@components/Typography'
import { FadeAndRotate, FadeIn, Parallax, Rotate } from '@components/Animations'
import Spacer from '@components/SpacerComponent'
import { ImageComponent } from '@components/ImageComponent'
import SpacerComponent from '@components/SpacerComponent'

// Hooks
import useSetBackgroundColor from '@hooks/useSetBackgroundColor'
import useIsMobile from '@hooks/useIsMobile'

// Assets
// @ts-expect-error
import directors from './images/directors.jpeg'
// @ts-expect-error
import seaTrip from './images/sea-trip.jpg'
import {
  Aws,
  BCorp,
  EscapeTheCity,
  LivingWage,
} from '@components/IconComponent'
import ImageSpacer from '@components/ImageSpacerComponent/ImageSpacerComponent'

const icons = [
  { label: 'Living wage employer', icon: LivingWage },
  { label: 'Escape the City', icon: EscapeTheCity },
  { label: 'B Corporation', icon: BCorp },
]

const Practice: React.FC = () => {
  const { ref } = useSetBackgroundColor()
  const isMobile = useIsMobile()
  const theme = useTheme()

  return (
    <SectionComponent ref={ref}>
      <Grid.Row>
        <HeadingSection
          verticalHeading='Practise what you preach'
          heading={
            <FadeIn>
              <Heading centered>
                Our <Highlight>pillars to live by</Highlight> are inspired by
                several well-being, cultural and sustainability movements.
              </Heading>
              <Spacer bottom='xl2' />
            </FadeIn>
          }
          content={[]}
          hasLink={false}
        />
      </Grid.Row>
      <Grid.Row>
        <Grid.SubGrid
          span={isMobile ? 'full' : 'half'}
          order={isMobile ? 1 : 2}
        >
          <Grid.Item columnStart={1} columnEnd={7}>
            <FadeAndRotate>
              <ImageSpacer>
                <ImageComponent alt='' src={directors} />
              </ImageSpacer>
            </FadeAndRotate>

            <Spacer bottom='xl' />
            <FadeAndRotate>
              <ImageSpacer>
                <ImageComponent alt='' src={seaTrip} />
              </ImageSpacer>
            </FadeAndRotate>
          </Grid.Item>
        </Grid.SubGrid>
        <Grid.SubGrid
          span={isMobile ? 'full' : 'half'}
          order={isMobile ? 1 : 2}
        >
          <SpacerComponent top='xl' />
          <Grid.Item column={'2 / 13'}>
            <FadeIn>
              <Paragraph size='lg'>
                We're under no illusion that this will ever be fully completed,
                but we’re working hard at developing an internal framework to
                tackle some of the worlds most important challenges:
              </Paragraph>
            </FadeIn>
            <Spacer top={'xl2'} />
            <FadeIn>
              <Heading2 font='secondary'>
                Inclusively and working together
              </Heading2>
            </FadeIn>
            <SpacerComponent bottom='xs' />
            <FadeIn>
              <Paragraph>
                Co production and inclusion of underrepresented communities.
              </Paragraph>
            </FadeIn>
            <Spacer top={'xl'} />
            <FadeIn>
              <Heading2 font='secondary'>Reduced inequalities</Heading2>
            </FadeIn>
            <SpacerComponent bottom='xs' />
            <FadeIn>
              <Paragraph>
                Fair and equal representation of female/male/diversity within
                roles.
              </Paragraph>
            </FadeIn>

            <Spacer top={'xl'} />

            <FadeIn>
              <Heading2 font='secondary'>Cultural Wellbeing</Heading2>
            </FadeIn>
            <SpacerComponent bottom='xs' />
            <FadeIn>
              <Paragraph>
                Promoting a work/life balance, ensuring we have a positive
                contribution to global wellbeing.
              </Paragraph>
            </FadeIn>
            <Spacer top={'xl'} />
            <FadeIn>
              <Heading2 font='secondary'>Climate Action</Heading2>
            </FadeIn>
            <SpacerComponent bottom='xs' />
            <FadeIn>
              <Paragraph>
                Striving for carbon neutrality, ensuring we’re environmentally
                responsible within our tech architecture and practices.{' '}
              </Paragraph>
            </FadeIn>
          </Grid.Item>
        </Grid.SubGrid>
      </Grid.Row>
      <Grid.Row>
        <Spacer top='xl3' />
        <Grid.Item columnStart={2} columnEnd={12}>
          <ul
            css={`
              display: flex;
              ${isMobile && 'flex-direction: column'};
              place-content: center;
              align-items: center;
              gap: ${isMobile ? theme.spacing.base : '10%'};
            `}
          >
            {icons.map(item => (
              <li>
                <Icon src={item.icon} alt={item.label} />
              </li>
            ))}
          </ul>
        </Grid.Item>
      </Grid.Row>
    </SectionComponent>
  )
}

export default Practice

const Icon = styled.img`
  height: ${({ theme }) => theme.spacing.xl};
  width: ${({ theme }) => theme.spacing.xl};
`
