import React from 'react'
import styled from 'styled-components/macro'

// Components
import Grid from '@components/ViewGridComponent'
import HeadingSection from '@components/HeadingSectionComponent'
import SectionComponent from '@components/SectionComponent'
import { FadeAndRotate, FadeIn } from '@components/Animations'
import Spacer from '@components/SpacerComponent'
import { ImageComponent as Image } from '@components/ImageComponent'
import SpacerComponent from '@components/SpacerComponent'
import ImageSpacer from '@components/ImageSpacerComponent/ImageSpacerComponent'
import {
  Heading,
  Heading2,
  Highlight,
  Paragraph,
  Title1,
} from '@components/Typography'

// Hooks
import useSetBackgroundColor from '@hooks/useSetBackgroundColor'
import useIsMobile from '@hooks/useIsMobile'

// Assets
import PotteryGang from '@assets/images/pottery-gang.jpg'
import PotteryGangWebp from '@assets/images/webp/pottery-gang.webp'
import SamWithGus from '@assets/images/sam-with-rocket-and-gus.jpg'
import SamWithGusWebp from '@assets/images/webp/sam-with-rocket-and-gus.webp'
import AgileKinetic from '@assets/images/agile-kinetic-mockup.png'
import AgileKineticWebp from '@assets/images/webp/agile-kinetic-mockup.webp'
import TfwPoster from '@assets/images/tfw-poster-train.png'
import TfwPosterWebp from '@assets/images/webp/tfw-poster-train.webp'

const Who: React.FC = () => {
  const { ref } = useSetBackgroundColor()
  const isMobile = useIsMobile()
  const headingColumn = isMobile ? '1 / 13' : '1 / 7'

  return (
    <SectionComponent ref={ref} id='who'>
      <Grid.Row>
        <HeadingSection
          verticalHeading='who'
          heading={
            <FadeIn>
              <Title1 centered={!isMobile}>
                Hey, <Highlight>we're Big Lemon</Highlight>,<br /> nice to meet
                you
              </Title1>
            </FadeIn>
          }
          content={[
            <FadeIn>
              <SpacerComponent top='lg' />
              <Paragraph centered={!isMobile} size='lg'>
                We're a small group of people with a bloody big mission.
              </Paragraph>
            </FadeIn>,
          ]}
          hasLink={false}
        />
      </Grid.Row>
      <Grid.Row>
        <SpacerComponent bottom={isMobile ? 'xl2' : 'xl3'} />
        <Grid.Item columnStart={1} columnEnd={13}>
          <FadeAndRotate direction='middle'>
            <ImageSpacer>
              <Image
                alt='BigLemon Team painting pottery'
                src={PotteryGangWebp}
                sources={[
                  { srcSet: PotteryGangWebp, type: 'image/webp' },
                  { srcSet: PotteryGang, type: 'image/jpeg' },
                ]}
              />
            </ImageSpacer>
          </FadeAndRotate>
        </Grid.Item>
      </Grid.Row>
      <SpacerComponent bottom={isMobile ? 'xl2' : 'xl3'} />
      <Grid.Row>
        <Grid.Item column={headingColumn} alignSelfCenter>
          <FadeIn>
            <Heading2 font='display'>
              We’re a bunch of passionate and value driven individuals that
              truly care about the impact of the products we produce.
            </Heading2>
          </FadeIn>
          <Spacer top={'base'} />
          <FadeIn>
            <Paragraph>
              Since 2014, we’ve been designing and building meaningful tech for
              good digital products that empower others to deliver positive
              change.
            </Paragraph>
          </FadeIn>
          <Spacer top={isMobile ? 'lg' : 'lg'} />
        </Grid.Item>

        <Grid.Item column={isMobile ? '1 / 13' : '8 / 13'}>
          <FadeAndRotate direction='right'>
            <ImageSpacer>
              <Image
                alt='Sam with Gus the dog'
                src={SamWithGusWebp}
                sources={[
                  { srcSet: SamWithGusWebp, type: 'image/webp' },
                  { srcSet: SamWithGus, type: 'image/png' },
                ]}
              />
            </ImageSpacer>
          </FadeAndRotate>
        </Grid.Item>
      </Grid.Row>
      {/* <Spacer bottom='xl3' /> */}
      {/* <Grid.Row>
        <Grid.Item
          column={isMobile ? '1 / 13' : '3 / 11'}
          alignSelfStretch
          justifySelfEnd
        >
          <FadeIn>
            <Heading centered>
              <Highlight>Solving challenges</Highlight> with original creativity
              and intelligent technology to make tech purposeful and a force for
              good.
            </Heading>
          </FadeIn>
        </Grid.Item>
      </Grid.Row>
      <SpacerComponent bottom='xl3' />
      <Grid.Row>
        <Grid.Item column={isMobile ? '1 / -1' : '1 / 7'}>
          <SpacerComponent top={isMobile ? '0' : 'xl2'} />
          <FadeAndRotate direction='left'>
            <ImageSpacer disableRightPadding={!isMobile}>
              <Image
                alt='Transport for Wales Poster'
                src={TfwPosterWebp}
                sources={[
                  { srcSet: TfwPosterWebp, type: 'image/webp' },
                  { srcSet: TfwPoster, type: 'image/png' },
                ]}
              />
            </ImageSpacer>
          </FadeAndRotate>
        </Grid.Item>
        {isMobile && <Spacer bottom='xl' />}
        <Grid.Item column={isMobile ? '1 / -1' : '7 / 13'}>
          <FadeAndRotate direction='right'>
            <ImageSpacer disableLeftPadding={!isMobile}>
              <Image
                alt='Mockups for Agile Kinetic'
                src={AgileKineticWebp}
                sources={[
                  { srcSet: AgileKineticWebp, type: 'image/webp' },
                  { srcSet: AgileKinetic, type: 'image/png' },
                ]}
              />
            </ImageSpacer>
          </FadeAndRotate>
        </Grid.Item>
      </Grid.Row> */}
    </SectionComponent>
  )
}

export default Who
